import axios, { AxiosRequestConfig } from 'axios'
import { AxiosAdapter } from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import store from '@/services/store'

import { useAuth } from '@/services/user/Auth0UserProvider'
import UserSession from '@/services/models/UserSession'

const conn = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    })
  ),
})

let isFetchingSession = false

conn.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // auth0 token
    if (!store.getters.plainAuth0Token && !isFetchingSession) {
      try {
        isFetchingSession = true
        const auth0 = useAuth()
        const token = await auth0.getTokenSilently()
        if (token) {
          store.commit('setAuth0Token', token)
        }
        if (store?.getters?.plainAuth0Token && config.headers) {
          config.headers['Authorization'] =
            'Bearer ' + store?.getters?.plainAuth0Token

          const response = await UserSession.get()
          if (response?.data) {
            store.commit('setUserSession', response?.data)
          }
        }
      } catch (error) {
        store.commit('clearAuth0Token')
        console.log('Failed to fetch user session', error)
      }
    }

    config.params = {
      ...(config.params || {}),
      user_session_id: UserSession.getStoredUserId(),
    }
    isFetchingSession = false
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

conn.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response
    if (status == 401 || status === 403) {
      axios
        .get('https://' + process.env.VUE_APP_AUTH0_DOMAIN + '/userinfo', {
          headers: {
            Authorization: `Bearer ${store?.getters?.plainAuth0Token}`,
          },
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            store.commit('clearAuth0Token')
            store.commit('clearUserSession')
          }
        })

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(conn(error.config))
        }, 2000)
      })
    }

    return Promise.reject(error)
  }
)

export default conn
