import restApiConnection from '@/services/api/providers/Connection'
import deliveryPlansResponse from '@/types/responses/deliveryPlansResponse'
import deliveryPlansRequest from '@/types/requests/deliveryPlansRequest'
import deliveryPlanInfo from '@/types/deliveryPlanInfo'
import RestApiBase from '@/services/models/RestApiBase'
import store from '@/services/store'

export default class DeliveryPlan extends RestApiBase {
  public static async all(): Promise<deliveryPlansResponse> {
    return this.sync('GetDeliveryPlans', this.selectAll)
  }

  public static async selectByDeliveryPlanIds(
    delivery_plan_ids: string[]
  ): Promise<deliveryPlansResponse> {
    const key =
      'DeliveryPlanSelectByDeliveryPlanIds' + delivery_plan_ids.join(',')
    return this.sync(key, this._selectByDeliveryPlanIds, delivery_plan_ids)
  }

  public static async selectAll(): Promise<deliveryPlansResponse> {
    return DeliveryPlan.where({})
  }

  public static async selectByParams(
    request: deliveryPlansRequest,
    forceUpdate: boolean = false
  ): Promise<deliveryPlansResponse> {
    const key = 'DeliveryPlanSelectByParams' + JSON.stringify(request)
    return this.sync(key, () => {
      return this.where(request, !forceUpdate)
    })
  }

  public static async _selectByDeliveryPlanIds(
    delivery_plan_ids: string[]
  ): Promise<deliveryPlansResponse> {
    let ret: deliveryPlanInfo[] = []
    const max_length = 100
    for (let i = 0; i < delivery_plan_ids.length / max_length + 1; i++) {
      const offset = i * max_length
      const limit = i * max_length + max_length

      const query = delivery_plan_ids.slice(offset, limit).join(',')

      if (query.length == 0) break

      const res = await DeliveryPlan.where({
        delivery_plan_ids: query,
        limit: max_length,
      })
      ret = ret.concat(res.data.delivery_plans)
    }
    return new Promise((resolve) => {
      resolve({
        data: {
          delivery_plans: ret,
          total_pages: 1,
        },
      } as deliveryPlansResponse)
    })
  }

  public static async where(
    request: deliveryPlansRequest,
    cache: boolean = true
  ): Promise<deliveryPlansResponse> {
    const user_session = store.getters.plainUserSession
    const user_id = user_session?.user_session_id || ''

    request['user_session_id'] = user_id
    return restApiConnection
      .get('/delivery_plans', { params: request, cache: cache })
      .then((data: deliveryPlansResponse) => {
        return DeliveryPlan.convertTimeFormat(data)
      })
  }

  public static convertTimeFormat(
    data: deliveryPlansResponse
  ): deliveryPlansResponse {
    ;(data?.data?.delivery_plans ?? []).map(
      (deliveryPlan: deliveryPlanInfo) => {
        if (deliveryPlan.leaving_warehouse_date_time) {
          deliveryPlan.leaving_warehouse_date_time =
            deliveryPlan.leaving_warehouse_date_time
              .replaceAll('-', '/')
              .split('.')[0]
        }
        if (deliveryPlan.returning_warehouse_date_time) {
          deliveryPlan.returning_warehouse_date_time =
            deliveryPlan.returning_warehouse_date_time
              .replaceAll('-', '/')
              .split('.')[0]
        }
      }
    )
    return data
  }
}
