
import { defineComponent, ref } from 'vue'
import { useAuth } from '@/services/user/Auth0UserProvider'
import mapLayout from '@/layouts/mapLayout.vue'
import driverModeLayout from '@/layouts/driverModeLayout.vue'
import axios from '@/services/api/providers/Connection'
import vLoaderAnimation from '@/components/template/v-loader-animation.vue'
import AppSyncClient from '@/services/query/providers/Connection'
import { useRouter } from 'vue-router'
import { IdToken } from '@auth0/auth0-spa-js'

export default defineComponent({
  name: 'App',
  setup() {
    // You can import 'loading' here and show a global loader if that's more convenient
    const { initializeAuth } = useAuth()
    const ready = ref<boolean>(false)
    const { push } = useRouter()

    let showPageAfterRedirect = true

    initializeAuth(
      {
        domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
        client_id: process.env.VUE_APP_AUTH0_CLIENTID as string,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE as string,
        useRefreshTokens: true,
      },
      (appState) => {
        if (appState && appState.targetUrl) {
          push(appState.targetUrl).then(() => (ready.value = true))
        }
      },
      () => (showPageAfterRedirect = false)
    ).then(() => {
      const auth0 = useAuth()
      if (auth0.isAuthenticated.value) {
        // tokenを取得
        auth0.getTokenSilently().then((token) => {
          // Bearer Tokenを設定
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          if (!showPageAfterRedirect) {
            ready.value = true
          }

          AppSyncClient.token = token
        })
        auth0.getIdTokenClaims().then((idTokenClaims: IdToken | undefined) => {
          const idToken = idTokenClaims?.__raw || ''
          axios.defaults.headers.common['X-Id-Token'] = idToken
        })
      } else {
        ready.value = true
      }
    })

    return {
      ready,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout
      }

      return 'mapLayout'
    },
  },
  components: {
    mapLayout: mapLayout,
    vLoaderAnimation,
  },
})
