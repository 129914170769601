import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import myOperationOffice from '@/types/myOperationOffice'
import UserSession from '@/services/models/UserSession'
import { AxiosResponse } from 'axios'

export default class MyOperationOffice extends RestApiBase {
  public static async get(): Promise<
    AxiosResponse<myOperationOffice | undefined>
  > {
    return this.sync('GetMyOperationOffice', this.getOwn)
  }

  public static getOwn = async (): Promise<
    AxiosResponse<myOperationOffice>
  > => {
    return restApiConnection.get('/my_operation_office', {
      cache: true,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    })
  }
}
