import restApiConnection from '@/services/api/providers/Connection'
import spotsResponse from '@/types/responses/spotsResponse'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'

export default class Spot extends RestApiBase {
  public static async all(): Promise<spotsResponse> {
    return this.sync('GetSpots', this.selectAll)
  }

  public static selectAll = async (): Promise<spotsResponse> => {
    return restApiConnection.get('/spots', {
      cache: true,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    })
  }
}
