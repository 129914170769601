import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import workloadUnitType from '@/types/responses/workloadUnit'
import { AxiosResponse } from 'axios'
import store from '@/services/store'

export default class SelfWorkload extends RestApiBase {
  public static async get(): Promise<AxiosResponse<workloadUnitType>> {
    return this.sync('GetWorkloads', this.getOwn)
  }

  public static getOwn = async (): Promise<AxiosResponse<workloadUnitType>> => {
    const user_session = store.getters.plainUserSession
    const user_id = user_session?.user_session_id || ''
    const getLimit = 10

    return restApiConnection.get(
      `/workload_units?user_session_id=${user_id}&limit=${getLimit}`,
      {
        headers: {
          'Cache-Control': 'max-age=0',
        },
      }
    )
  }
}
