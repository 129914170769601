import { createStore } from 'vuex'
import Api from '@/services/api/ApiServiceFabrick'
import errorResponse from '@/types/responses/errorResponse'

const store = createStore({
  state: {
    auth0Token: sessionStorage.getItem('auth0Token')
      ? JSON.parse(sessionStorage.getItem('auth0Token')!)
      : null,
    userSession: sessionStorage.getItem('userSession')
      ? JSON.parse(sessionStorage.getItem('userSession')!)
      : null,
    myOperationOffice: sessionStorage.getItem('myOperationOffice')
      ? JSON.parse(sessionStorage.getItem('myOperationOffice')!)
      : null,
    vehiclesResult: null,
  },
  mutations: {
    setUserSession(state, sessionData) {
      state.userSession = sessionData
      sessionStorage.setItem('userSession', JSON.stringify(sessionData))
    },
    clearUserSession(state) {
      state.userSession = null
      sessionStorage.removeItem('userSession')
    },
    setAuth0Token(state, auth0Data) {
      state.auth0Token = auth0Data
      sessionStorage.setItem('auth0Token', JSON.stringify(auth0Data))
    },
    clearAuth0Token(state) {
      state.auth0Token = null
      sessionStorage.removeItem('auth0Token')
    },
    setMyOperationOffice(state, myOperationOffice) {
      state.myOperationOffice = myOperationOffice
      sessionStorage.setItem(
        'myOperationOffice',
        JSON.stringify(myOperationOffice)
      )
    },
    setVehiclesResult(state, vehiclesResult) {
      state.vehiclesResult = vehiclesResult
    },
  },
  actions: {
    fetchVehiclesResult({ commit }) {
      Api()
        .getVehicles((vehiclesResult: any) => {
          commit('setVehiclesResult', vehiclesResult)
        })
        .catch((error: errorResponse) => {
          console.log(error)
          throw error
        })
    },
  },
  getters: {
    plainUserSession: (state) => JSON.parse(JSON.stringify(state.userSession)),
    plainAuth0Token: (state) => JSON.parse(JSON.stringify(state.auth0Token)),
    plainMyOperationOffice: (state) =>
      JSON.parse(JSON.stringify(state.myOperationOffice)),
  },
})

export default store
