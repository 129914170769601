import restApiConnection from '@/services/api/providers/Connection'
import driversResponse from '@/types/responses/driversResponse'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'

export default class Driver extends RestApiBase {
  public static async all(
    forceUpdate: boolean = false
  ): Promise<driversResponse> {
    return this.sync('GetDrivers', () => {
      return this.selectAll(!forceUpdate)
    })
  }

  public static selectAll = async (
    cache: boolean = true
  ): Promise<driversResponse> => {
    return restApiConnection.get('/drivers', {
      cache: cache,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    })
  }
}
